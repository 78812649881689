import qs from 'qs';
import { ReadonlyURLSearchParams } from 'next/navigation';

const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*(\?\S*)?$/;
export const isURL = (str: string): boolean => {
  return urlRegex.test(str);
};

export const buildQueryParams = (params: {}) =>
  qs.stringify(params, { addQueryPrefix: true });

export const addToQueryString = (
  { name, value }: { name: string; value: string },
  searchParams: ReadonlyURLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);
  params.set(name, value);

  return params.toString();
};

export const removeFromQueryString = (
  { name, value }: { name: string; value?: string },
  searchParams: ReadonlyURLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);
  params.delete(name, value);

  return params.toString();
};

export function buildSrcUrl(path: string) {
  if (!path) return;
  return `${process.env.NEXT_PUBLIC_STRAPI_CMS_URL}${path}`;
}

export function enhancedImageWithParameter(url: string | undefined) {
  if (!url) return '';
  return url.replace(/(file=)\d+/, '$17');
}
