import Image from 'next/image';

import { customIcons } from './Customs';
import { creditcardsIconsName } from './creditCardIconsName';

const customIconsNames = Object.getOwnPropertyNames(customIcons);
export interface IconsProps {
  name: string;
  isImage?: boolean;
  style?: React.CSSProperties | undefined;
  width?: number;
  height?: number;
}

const Icon = ({ name, isImage = false, style, width, height }: IconsProps) => {
  const isCreditCard = creditcardsIconsName.includes(name);
  if (isImage || isCreditCard) {
    const iconSrc = isCreditCard
      ? `/img/icons/credit-card/${name}.svg`
      : `/img/icons/${name}.png`;
    return (
      <span
        className="icon"
        style={{ ...style, position: 'relative', display: 'block' }}
      >
        <Image
          src={iconSrc}
          fill={true}
          width={width}
          height={height}
          sizes="auto"
          alt={`Icon for ${name}`}
        />
      </span>
    );
  }

  if (customIconsNames.includes(name)) {
    const CustomIcon = customIcons[name as keyof typeof customIcons];
    return (
      <span className="icon" style={style}>
        <CustomIcon />
      </span>
    );
  }

  return (
    <span className="icon material-symbols-outlined" style={style}>
      {name}
    </span>
  );
};

export default Icon;
