import Link, { LinkProps } from 'next/link';
import classNames from 'classnames';

import { isURL } from '@/utils/url';

import styles from './Button.module.scss';
import { useFormStatus } from 'react-dom';

export interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<
    HTMLAnchorElement | HTMLButtonElement | LinkProps
  > {
  id?: string;
  title?: string;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  href?: string;
  extraClassName?: string;
  loading?: boolean;
  size?: 'default' | 'small' | 'thin' | 'fit';
  full?: boolean;
  weight?: '500' | '600' | '700';
  radius?: 'default' | 'short';
  transform?: 'initial' | 'uppercase' | 'lowercase' | 'capitalize';
  variant?: 'contained' | 'outlined' | 'text' | 'text-underlined';
  color?:
    | 'primary'
    | 'primary-inverse'
    | 'secondary'
    | 'secondary-inverse'
    | 'alternative';
}

export type ButtonProps =
  | ({ href?: null | undefined | '' } & ButtonBaseProps &
      React.ButtonHTMLAttributes<HTMLButtonElement>)
  | ({ href: string } & ButtonBaseProps &
      React.AnchorHTMLAttributes<HTMLAnchorElement | LinkProps>);

// primary - laranja com branco
// primary-inverse = branco com laranja

// secondary - preto com branco
// secondary-inverse = branco com preto

// alternative - roxo

const Button = ({
  children,
  type = 'button',
  href,
  size = 'default',
  weight = '500',
  radius = 'default',
  transform = 'uppercase',
  variant = 'contained',
  color = 'primary',
  extraClassName,
  full = false,
  loading = false,
  disabled,
  ...rest
}: ButtonProps) => {
  const isLink = Boolean(href);
  const classes = classNames([
    'button',
    styles.button,
    styles[`button--${size}`],
    styles[`button--weight-${weight}`],
    styles[`button--transform-${transform}`],
    styles[`button--radius-${radius}`],
    styles[`button--${variant}`],
    styles[`button--${color}`],
    { [`${styles['button--full']}`]: full },
    { [`${styles['button--disabled']}`]: disabled },
    extraClassName,
  ]);

  if (isLink) {
    const LinkComponent = href && isURL(href) ? 'a' : Link;
    return (
      <LinkComponent href={href ? href : '/'} className={classes} {...rest}>
        {children}
      </LinkComponent>
    );
  }

  return (
    <button
      disabled={disabled || loading}
      type={type}
      className={classes}
      {...rest}
    >
      {loading ? <span className={styles['button__loader']} /> : children}
    </button>
  );
};

export default Button;
