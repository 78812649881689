export const creditcardsIconsName = [
  'alipay',
  'amex',
  'american-express',
  'code-front',
  'code',
  'diners',
  'diners-club',
  'discover',
  'elo',
  'generic',
  'hiper',
  'hipercard',
  'jcb',
  'maestro',
  'mastercard',
  'mir',
  'paypal',
  'unionpay',
  'visa',
];
